import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { Flex, Button, FormControl, FormLabel } from "@chakra-ui/react";
import { AdminContext } from "context";
import { EToastStatus, EEmployers, EActionType } from "enums";
import {
  useState,
  useContext,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
} from "react";
import { IEmployer } from "types";
import { addNewEmployer, deleteEmployer, updateEmployer } from "services";
import { EmployerFormSub } from "components/employerForm";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ConfirmDialog, SelectPartner } from "@components";
import { formatNickname } from "@utils";

interface AddPartnerModalProps {
  showModal: boolean;
  onDecline: () => void;
  action: () => void;
  data?: IEmployer;
}

const validationSchema = Yup.object().shape({
  employerName: Yup.string().required(),
  TPAName: Yup.string().required(),
  partnerId: Yup.string().required(),
  employerNickname: Yup.string().required(),
});

export const EmployerModal = ({
  showModal,
  onDecline,
  data,
  action,
}: AddPartnerModalProps) => {
  const cancelRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<IEmployer>();
  const { showToast } = useContext(AdminContext);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      employerName: data?.employerName,
      employerNickname: data?.employerNickname,
      TPAName: data?.TPAName,
      partnerId: data?.partnerId,
    },
    validationSchema,
    onSubmit: async () => {
      handleAddNewEmployer();
    },
  });
  const { values, setFieldValue, resetForm, isValid, dirty } = formik;

  const handleAddNewEmployer = async () => {
    try {
      setIsSubmitting(true);
      if (data) {
        await updateEmployer({ ...values, _id: data._id });
        showToast("Success", "Update Employer success!", EToastStatus.SUCCESS);
      } else {
        await addNewEmployer(values);
        showToast("Success", "Create Employer success!", EToastStatus.SUCCESS);
      }
      action();
      onDecline();
      resetForm();
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    resetForm({
      values: {
        employerName: data?.employerName,
        employerNickname: data?.employerNickname,
        TPAName: data?.TPAName,
        partnerId: data?.partnerId,
      },
    });
  }, [data]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    const type = e?.target?.name;
    if (!type) return;
    if (type === EEmployers.EmployerNickname) {
      return setFieldValue(type, formatNickname(value))
    }
    setFieldValue(type, value);
  };

  const handlePartnerChange = (e: { value: string; label: string }) => {
    const value = e.value;
    formik.setFieldValue(EEmployers.PartnerId, value);
  };

  const handleDeleteEmployer = async () => {
    try {
      await deleteEmployer(data._id);
      showToast(
        "Success",
        "Delete Employer successfully!",
        EToastStatus.SUCCESS
      );
      action();
      onDecline();
      setOpenConfirmDialog(false);
    } catch (error: any) {
      showToast("Error", error?.response?.data?.message, EToastStatus.ERROR);
    } finally {
      setIsSubmitting(false);
      setOpenConfirmDialog(false);
    }
  };

  const handleClose = () => {
    onDecline();
    resetForm();
  };

  const isDisabled = useMemo(() => {
    return !dirty || !isValid;
  }, [dirty, isValid]);

  return (
    <Modal
      finalFocusRef={cancelRef}
      onClose={handleClose}
      isOpen={showModal}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data ? "Edit Employer" : "Add Employer"} </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex gap={6} flexDirection="column">
            <Flex gap={6}>
              <EmployerFormSub.Input
                name={EEmployers.EmployerName}
                value={values?.employerName}
                onChange={handleInputChange}
                error={error?.employerName}
              />
            </Flex>
            <EmployerFormSub.Input
              name={EEmployers.EmployerNickname}
              value={values?.employerNickname}
              onChange={handleInputChange}
              error={error?.employerNickname}
            />
            <Flex gap={6}>
              <EmployerFormSub.Input
                name={EEmployers.TPAName}
                value={values?.TPAName}
                onChange={handleInputChange}
                error={error?.TPAName}
              />
            </Flex>
            <Flex gap={6}>
              <FormControl>
                <FormLabel display="flex" alignItems="center" gap={2}>
                  Partner
                </FormLabel>
                <SelectPartner
                  handlePartnerChange={handlePartnerChange}
                  data={data}
                />
              </FormControl>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter gap={5}>
          {data && (
            <Button
              isLoading={false}
              colorScheme="red"
              onClick={() => setOpenConfirmDialog(true)}
            >
              Delete
            </Button>
          )}
          <Button
            isLoading={isSubmitting}
            isDisabled={isDisabled}
            colorScheme="purple"
            onClick={handleAddNewEmployer}
          >
            {data ? "Save" : "Add "}
          </Button>
        </ModalFooter>
      </ModalContent>
      <ConfirmDialog
        showModal={openConfirmDialog}
        onAccept={handleDeleteEmployer}
        onDecline={() => setOpenConfirmDialog(false)}
        title={EActionType.DELETE}
        content={<>Do you want to {EActionType.DELETE} this Employer?</>}
      />
    </Modal>
  );
};
