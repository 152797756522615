import { FC, useEffect, useState } from "react";
import Select from "react-select";
import { getPartnerListForCode } from "services";
import { IPartnerForm } from "types";

interface IProps {
  handlePartnerChange: (e: { value: string; label: string }) => void;
  data: any;
}

const SelectPartner: FC<IProps> = ({ handlePartnerChange, data }) => {
  const [partnerList, setPartnerList] = useState<IPartnerForm[]>();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [pagePartners, setPagePartners] = useState(1);
  const [loadingPartners, setLoadingPartners] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setLoadingPartners(true);
        const res = await getPartnerListForCode(pagePartners, searchValue);
        const newPartnerList = res.result?.map((item: any) => {
          return { value: item._id, label: item.name };
        });
        if (pagePartners === 1) {
          setPartnerList(newPartnerList);
        } else {
          setPartnerList((prev) => [...prev, ...newPartnerList]);
        }
        setHasNextPage(res.hasNextPage);
      } catch (err) {
      } finally {
        setLoadingPartners(false);
      }
    })();
  }, [pagePartners, searchValue]);

  const handleInputChange = (inputValue: string) => {
    setSearchValue(inputValue);
  };

  const handleScrollToBottomPartners = () => {
    if (!hasNextPage) return;
    setPagePartners((prev) => prev + 1);
  };

  return (
    <Select
      defaultValue={{
        label: data?.partnerName || "Select",
        value: data?.partnerId || "",
      }}
      onChange={handlePartnerChange}
      options={partnerList}
      onMenuScrollToBottom={handleScrollToBottomPartners}
      isLoading={loadingPartners}
      onInputChange={handleInputChange}
    />
  );
};

export default SelectPartner;