import { apiInstance } from "@apis";
import { PAGE_SIZE } from "@constants";
import { getFilterDateParams } from "@utils";
import { EApiUrl } from "enums";
import moment from "moment";
import {
  IBaseRes,
  ICodeDetailRes,
  ICodeReq,
  ICodeRes,
  ICodeUpdate,
  IFilterDate,
  IPagable,
  IPartnerRes,
  ISortTable,
} from "types";

const size = PAGE_SIZE;

export const getCodeList = async (
  filterDate: IFilterDate,
  search?: string, page?: number, sort?: ISortTable
) => {
  const filter = {
    ...getFilterDateParams(filterDate, 'minStartDate', 'maxStartDate'),
    ...getFilterDateParams(filterDate, 'minEndDate', 'maxEndDate')
  };
  const { data } = await apiInstance.get<IBaseRes<IPagable<ICodeRes[]>>>(EApiUrl.CODES_URL, {
    params: {
      search,
      ...sort,
      ...filter,
      page,
      size,
    }
  });
  return data?.data;
};

export const getPartnerListForCode = async (page = 1, search = "") => {
  const { data } = await apiInstance.get<IBaseRes<IPagable<IPartnerRes[]>>>(
    EApiUrl.PARTNER_URL,
    { params: { isActive: true, size: 15, page, search } }
  );
  return data?.data;
};

export const getCodeDetail = async (
  codeId:string
) => {
  const {data} = await apiInstance.get<IBaseRes<ICodeDetailRes>>(`${EApiUrl.CODES_URL}/${codeId}`);
  return data.data;
};

export const createCode = async (code: ICodeReq) => {
  const { data } = await apiInstance.post(EApiUrl.CODES_URL, { ...code });
  return data;
}

export const updateCode = async (code: ICodeUpdate) => {
  const { _id, ...rest } = code;
  const { data } = await apiInstance.put(`${EApiUrl.CODES_URL}/${_id}`, { ...rest });
  return data;
}

export const deleteCode = async (id: string) => {
  const { data } = await apiInstance.delete(`${EApiUrl.CODES_URL}/${id}`);
  return data;
}

export const exportCode = async () => {
  const timeZone = moment.tz.guess();
  const data = await apiInstance.get(`${EApiUrl.CODES_URL}/export/export-csv?timezone=${timeZone}`);
  return data;
}